import { AppLayoutProps } from "@amzn/awsui-components-react";
import { matchRoutes } from "react-router-dom";
import { finPressRoles, Roles } from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";

interface PageSetting {
    layoutSetting: {
        navigationWidth: number;
        contentType: AppLayoutProps.ContentType;
        disableContentPaddings?: boolean
        maxContentWidth?: number
    };
    permissionSetting: {
        read: Set<Roles>;
    };
}

const DEFAULT_SETTING: PageSetting = {
    layoutSetting: {
        navigationWidth: 250,
        contentType: "default",
    },
    permissionSetting: {
        read: new Set([Roles.UNKNOWN]),
    },
};

const PAGE_SETTING: { [p: string]: PageSetting } = {
    [Paths.HOME]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([...finPressRoles]),
        },
    },
    [Paths.MDXLIBRARY]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.ACTIVEREPORTS]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.REFERENCEREPORTS]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin, Roles.FinanceLeader, Roles.BusinessLeader, Roles.FGBSLeader, Roles.SFPA_ReportAuthor, Roles.SFPA_ReportViewer, Roles.SFPA_ReportAuthor, Roles.DCFPA_ReportAuthor, Roles.DCFPA_ReportViewer])
        }
    },
    [Paths.ARCHIVEREPORTS]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin, Roles.FinanceLeader]),
        },
    },
    [Paths.CREATEREPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "wizard",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.EXTERNALREPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.CREATEREVIEW]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "wizard",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.VIEWREPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "cards",
        },
        permissionSetting: {
            read: new Set([
                Roles.Admin,
                Roles.FinanceLeader,
                Roles.BusinessLeader,
                Roles.FGBSLeader,
            ]),
        },
    },
    [Paths.BRIDGINGCOMMENTARY]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([Roles.BridgeOwner]),
        },
    },
    [Paths.BRIDGE]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "wizard",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.FILLOUTBRIDGE]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin, Roles.BridgeOwner, Roles.DCFPA_ReportAuthor, Roles.SFPA_ReportAuthor]),
        },
    },
    [Paths.REPORTBRIDGING]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([Roles.Admin, Roles.DCFPA_ReportAuthor, Roles.SFPA_ReportAuthor]),
        },
    },
    [Paths.THEMES]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "table",
        },
        permissionSetting: {
            read: new Set([Roles.Admin]),
        },
    },
    [Paths.BUILDREPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([Roles.SFPA_ReportAuthor, Roles.Admin]),
        },
    },
    [Paths.VIEWCUSTOMREPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
        },
        permissionSetting: {
            read: new Set([Roles.SFPA_ReportAuthor, Roles.SFPA_ReportViewer]),
        }
    },
    [Paths.REPORT_BUILDER]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
            disableContentPaddings: true,
            maxContentWidth: Number.MAX_VALUE,
        },
        permissionSetting: {
            read: new Set([Roles.DCFPA_ReportAuthor]),
        }
    },
    [Paths.REPORT_BUILDER_WORKFLOW]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
            disableContentPaddings: true,
            maxContentWidth: Number.MAX_VALUE,
        },
        permissionSetting: {
            read: new Set([Roles.DCFPA_ReportAuthor, Roles.DCFPA_ReportViewer]),
        }
    },
    [Paths.REPORT_BUILDER_REPORT]: {
        layoutSetting: {
            navigationWidth: 250,
            contentType: "default",
            disableContentPaddings: true,
            maxContentWidth: Number.MAX_VALUE,
        },
        permissionSetting: {
            read: new Set([Roles.DCFPA_ReportAuthor, Roles.DCFPA_ReportViewer]),
        }
    },
}

export const getPageSettings = (path: string) => {
    const matches = getMatchedRoutes(path);
    return matches ? PAGE_SETTING[matches[0].route.path] : DEFAULT_SETTING;
};

export const getMatchedRoutes = (path: string) => {
    const routes = Object.keys(PAGE_SETTING).map((path: string) => {
        return { path };
    });
    return matchRoutes(routes, path);
};
