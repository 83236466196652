import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {BreadcrumbState, setBreadcrumb} from "src/features/breadcrumbs/breadcrumbSlice";


export const useBreadcrumb = (breadcrumb: BreadcrumbState) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // set on mounto
        dispatch(setBreadcrumb(breadcrumb.crumbs))
        // Reset on unmount
        return () => {dispatch(setBreadcrumb([]))}
    }, [breadcrumb.crumbs]);
}
