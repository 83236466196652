const Paths = {
    HOME: "/",
    MDXLIBRARY: "/mdx-library",
    BRIDGINGCOMMENTARY: "/bridging-commentary",
    REPORTBRIDGING: "/report-bridging/:reportId",
    CREATEREPORT: "/create-report",
    ACTIVEREPORTS: "/active-reports",
    REFERENCEREPORTS: "/published-reports",
    ARCHIVEREPORTS: "/archive-reports",
    VIEWREPORT: "/view-report",
    EXTERNALREPORT: "/create-external-report",
    CREATEREVIEW: "/create-review",
    BRIDGE: "/create-bridge",
    FILLOUTBRIDGE: "/fillout-bridge",
    THEMES: "/themes",
    BUILDREPORT : "/build-report",
    VIEWCUSTOMREPORT : "/view-byor/:workflow_id/:execution_id",
    // Report builder routes
    REPORT_BUILDER: "/report-builder",
    REPORT_BUILDER_REPORT: "/report-builder/reports/:workflowId",
    REPORT_BUILDER_WORKFLOW: "/report-builder/:workflowId",
}

export default Paths;