import {ReportTable, WorkflowManager} from "@amzn/fin-press-report-builder-ui";
import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateBridge from "src/components/Bridging/CreateBridge";
import BridgingCommentaryPage from "src/components/BridgingCommentaryPage/BridgingCommentaryPage";
import RouteGuard from "src/components/Common/RouteGuard";
import CreateReportPage from "src/components/CreateReportPage/CreateReportPage";
import CreateReviewPage from "src/components/CreateReviewPage/CreateReviewPage";
import ExternalReportContainer from "src/components/ExternalReportPage/ExternalReportContainer";
import FillOutBridgePage from "src/components/FillOutBridgePage/FillOutBridgePage";
import HomePage from "src/components/HomePage/HomePage";
import MDXLibraryPage from "src/components/MDXLibraryPage/MDXLibraryPage";
import Paths from "src/components/PageConfig/Paths";
import ReportBridgingPage from "src/components/ReportBridgingPage/ReportBridgingPage";
import ActiveReportLibraryPage from "src/components/ReportLibraryPage/ActiveReportLibraryPage";
import ArchiveReportLibraryPage from "src/components/ReportLibraryPage/ArchiveReportLibraryPage";
import ReferenceReportLibraryPage from "src/components/ReportLibraryPage/ReferenceReportLibraryPage";
import { ThemesPage } from "src/components/Themes/ThemesPage";
import ViewReportPage from "src/components/ViewReportPage/ViewReportPage";
import { createWorkflow } from "src/features/byor/components/createWorkflow";
import { viewCustomReport } from "src/features/byor/components/viewCustomReport";
import { ReportBuilderScreen } from "src/screens";

interface AppRouterProps {
    navigationOpen: boolean;
    navigationToggle: (open: boolean) => void;
}

const AppRouter = ({ navigationOpen, navigationToggle }: AppRouterProps) => {
    return (
        <Routes>
            <Route path={Paths.HOME} element={<RouteGuard />}>
                <Route index Component={HomePage} />
                <Route path={Paths.MDXLIBRARY} Component={MDXLibraryPage} />
                <Route path={Paths.CREATEREPORT} Component={CreateReportPage} />
                <Route
                    path={Paths.ACTIVEREPORTS}
                    Component={ActiveReportLibraryPage}
                />
                <Route
                    path={Paths.REFERENCEREPORTS}
                    Component={ReferenceReportLibraryPage}
                />
                <Route
                    path={Paths.ARCHIVEREPORTS}
                    Component={ArchiveReportLibraryPage}
                />
                <Route path={Paths.VIEWREPORT} Component={ViewReportPage} />
                <Route
                    path={Paths.EXTERNALREPORT}
                    Component={ExternalReportContainer}
                />
                <Route path={Paths.CREATEREVIEW} Component={CreateReviewPage} />
                <Route path={Paths.BRIDGE} Component={CreateBridge} />
                <Route
                    path={Paths.REPORTBRIDGING}
                    Component={ReportBridgingPage}
                />
                <Route
                    path={Paths.BRIDGINGCOMMENTARY}
                    Component={BridgingCommentaryPage}
                />
                <Route
                    path={Paths.FILLOUTBRIDGE}
                    Component={FillOutBridgePage}
                />
                <Route path={Paths.THEMES} Component={ThemesPage} />
                <Route path={Paths.BUILDREPORT} Component={createWorkflow} />
                <Route
                    path={Paths.VIEWCUSTOMREPORT}
                    Component={viewCustomReport}
                />
                {/*Report builder routes*/}
                <Route path={Paths.REPORT_BUILDER} element={<ReportBuilderScreen />}>
                    <Route index element={<WorkflowManager navigationOpen={navigationOpen} navigationToggle={navigationToggle} />} />
                    <Route path={"reports/:workflowId"} element={<ReportTable />}/>
                    <Route path={":workflowId"} element={<WorkflowManager navigationOpen={navigationOpen} navigationToggle={navigationToggle} />}/>
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
